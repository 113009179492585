.modal-otp-booking {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    .ant-modal-header {
        border-bottom: none;
        padding-top: 20px;
        padding-bottom: 0px;
        .ant-modal-title {
            text-align: center;
            font-weight: bold;
            font-size: 20px;
        }
    }
    .ant-modal-body {
        padding: 20px;
    }
    .otp {
        padding-bottom: 10px;
        > div {
            justify-content: space-between;
        }
    }
    .input-otp {
        width: 48px;
        height: 48px;
        color: #222;
        input {
            width: 100% !important;
            height: 100%;
            border: 2px solid #a3a3a3;
            border-radius: 4px;
            &:focus {
                outline: none;
            }
        }
    }
    .message {
        &__otp {
            font-size: 14px;
            color: #e54d3e;
            padding: 0px 5px 5px;
        }
        &__error {
            color: #e54d3e;
            margin-top: -10px;
            font-size: 13px;
            padding: 0px 5px 5px;
        }
    }
    .sendOTP {
        margin-bottom: 25px;
        font-size: 14px;
        &__count {
            color: #e54d3e;
        }
        &__resend {
            text-decoration: underline;
            cursor: pointer;
            &.hide {
                cursor: default;
                text-decoration: none;
                padding-right: 5px;
                color: #a3a3a3;
            }
        }
    }
    .button {
        width: 100%;
        border-radius: 4px;
        height: 48px;
        font-family: 'Segoe UI', Helvetica, Arial,
            sans-serif;
        font-size: 18px;
    }
}
