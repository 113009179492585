@import '../../../utils/mixin.scss';

.new-header {
    background-color: #ffffff;
    box-shadow: 0px 2px 8px rgba(198, 198, 198, 0.25);
    border-bottom: 1px solid rgba(238, 238, 238, 0.7);
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-weight: 400;
    position: relative;

    &__container {
        padding: 0 16px;
        margin: auto;
        max-width: 1200px;
    }
}

.nav-bar {
    font-family: 'Segoe UI', sans-serif;
    padding: 5px 0px;
    height: 66px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(sx) {
        justify-content: space-between;
        padding: 5px 0px;
    }

    &__menu {
        position: fixed;
        top: 0;
        bottom: 0;
        background-color: #ffffff;
        right: -100%;
        z-index: 99;
        overflow: auto;
        transition: all 300ms ease-in-out;

        &.active {
            right: 0;
            width: 100%;
            padding: 1rem 20px;
        }
    }

    &__close {
        display: inline-block;
        background-color: #f7f7f7;
        border-radius: 4px;
        margin: auto;
        position: absolute;
        top: 1.5rem;
        right: 25px;

        @include breakpoint(sx) {
            // background-color: unset;
        }

        img {
            padding: 6px;
        }
    }

    &__logo {
        display: inline-block;
        margin-top: 10px;

        img {
            width: 100%;
        }
    }

    &__logo-main {
        img {
            height: 30px;
        }

        @media screen and (min-width: 500px) {
            img {
                height: 50px;
                // --tw-translate-x: -50%;
                transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            }
        }
    }

    &__action {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .menu {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 45px;

        .menu-item {
            @include breakpoint(sx) {
                padding: 12px 5px 12px 25px;
            }
        }

        .menu-item-text {
            font-family: 'Segoe UI', sans-serif;
            font-weight: 600;
            font-size: 16px;
            color: #262626;
            border-bottom: 2px solid transparent;

            &.active,
            &:hover {
                color: #20489B;
            }
        }

        padding-left: 0rem;

        &-item {
            list-style: none;
            box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            padding-top: 0;
            display: flex;
            align-items: center;
            position: relative;

            &-text {
                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
                font-weight: 500;
                // text-transform: uppercase;
                font-size: 1rem;
                color: #000;
                font-feature-settings: 'ss01' on;
            }

            .icon--mb {
                margin-right: 12px;
                width: 24px;
                height: 24px;
            }

            &.booking {
                display: block;
            }
        }

        a {
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                right: 9px;
                top: 50%;
                transform: translateY(-50%);
                background-image: url(../../../images/fillBlue/chevronRight.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 7px;
                height: 13px;
            }
        }

        &-action {
            padding: 0 10px;

            &__button {
                display: inline-block;
                background-color: #ffcc33;
                border-radius: 4px;
                color: #000000;
                text-transform: uppercase;
                font-size: 1.1rem;
                font-weight: 600;
                text-align: center;
                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
                padding: 0.6rem;
                width: 100%;
            }
        }
    }
}

.menu-button {
    margin-left: 10px;
}

.booking-button {
    // font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    border: 1px solid #000;
    border-radius: 8px;
    flex-direction: row;
    justify-content: center;
    padding: 2px 12px;
    display: none;

    span {
        white-space: nowrap;
        text-transform: uppercase;
        margin-left: 8px;
        font-size: 16px;
        color: #000;
    }
}

.login-button {
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2px 12px;
    background: #EF4E24;
    cursor: pointer;

    .member-icon {
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            min-width: 20px;
        }
    }

    span {
        max-width: 135px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        white-space: nowrap;
        // text-transform: uppercase;
        font-size: 16px;
        color: #fff;
        // @include breakpoint(sx) {
        //     max-width: 110px;
        // }
    }

    &:hover {
        background-color: #20489B;
    }

    img {
        width: 16px;
    }
}

.menu-topup {
    //  font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #111;

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        font-weight: 300;
    }
}

@media only screen and (min-width: 1025px) {
    .login-button {
        // font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
        transition: all 0.3s;

        span {
            font-size: 16px;
        }

        img {
            width: 22px;
        }

        .member-icon {
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
                min-width: 20px;
            }
        }

        &:hover {
            background-color: #20489B;

            span {
                color: #fff;
            }
        }
    }

    .booking-button {
        display: flex;
    }

    .nav-bar {
        s &__logo-main {
            width: auto;
            margin-top: -12px;
        }

        &__menu {
            // width: auto;
            position: relative;
            transition: none;
            right: 0;
            // margin-left: auto;
        }

        .menu {
            display: flex;
            column-gap: 30px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 0 0;
            margin: 0;
            list-style-type: none;
            color: #262626;

            .menu-item {
                box-shadow: none;
                margin: 0;

                .icon--mb {
                    display: none;
                }

                a {
                    font-size: 16px;

                    &:after {
                        content: none;
                    }
                }

                &.booking {
                    display: none;
                }
            }

            .menu-item-text {
                border-bottom: 2px solid transparent;

                &.active,
                &:hover {
                    // border-bottom: 2px solid #EF4E24;
                        color: #20489B;
                }
            }

            .lucky-wheel {
                display: none;
            }
        }
    }

    .nav-bar__logo,
    .menu-action,
    .nav-bar__close,
    .menu-button {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .new-header {
        &__container {
            padding: 0;
        }
    }
}